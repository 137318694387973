import {Helmet} from './helmet';
import {ReactElement} from 'react';
import {MessageDescriptor} from '../i18n/message-descriptor';
import {useSettings} from '../core/settings/use-settings';
import {MetaTag} from '../seo/meta-tag';

type TitleChild =
  | string
  | null
  | ReactElement<MessageDescriptor>
  | MessageDescriptor;
export type TitleMetaTagChildren = TitleChild | TitleChild[];

interface StaticPageTitleProps {
  children: TitleMetaTagChildren;
  canonicalUrl?: string; // Add a prop for canonical URL
}
export function StaticPageTitle({
  children,
  canonicalUrl,
}: StaticPageTitleProps) {
  const {
    branding: {site_name},
  } = useSettings();

  return (
    <Helmet>
      {children ? (
        <title>
          {children as any} - {site_name}
        </title>
      ) : (
        <></>
      )}
      {canonicalUrl ? (
        // @ts-ignore
        <canonical href={canonicalUrl}></canonical>
      ) : (
        <></>
      )}
    </Helmet>
  );
}
